import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import inclinaison from "../../../../assets/animations/inclinaison.gif"
import { useMediaQuery } from "react-responsive";

export const Animation = ({
    nextStep
}) => {
    const isSmallMobile = useMediaQuery({ maxWidth: 376 });
    const { t } = useTranslation('components/all');
    return(
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            height: "90vh",
            flexDirection: "column"
        }}>
            <img
                src={inclinaison}
                style={{
                    width: isSmallMobile ? "75vw" : "85vw",
                    marginBottom: "20px"
                }}
            />
            <Box sx={{
                width: "90%",
                marginBottom: "50px"
            }}>
                <Typography
                    color="white"
                    fontSize={isSmallMobile ? "30px" : "40px"}
                    fontWeight={"light"}
                    fontFamily={"Helvetica Neue"}
                >
                    {t('camera.inclination.intro')}
                </Typography>
            </Box>
            <Box sx={{
                width: "90px",
                height: "90px",
                borderRadius: "100px",
                border: "3px white dashed",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Box 
                    onClick={nextStep}
                    sx={{
                        fontFamily: "Helvetica Neue",
                        backgroundColor: "white",
                        width: "80%",
                        height: "80%",
                        borderRadius: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Typography
                        fontSize="25px"
                        fontWeight="bold"
                    >
                        GO
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}