import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Main from "./pages/Main";

import "./i18n.js";
import { i18n } from "./i18n.js";

import * as poseDetection from '@tensorflow-models/pose-detection';
import * as tf from '@tensorflow/tfjs';

import '@mediapipe/pose';
import '@tensorflow/tfjs-backend-webgl';

function App() {
  const [userInfoSaved, setUserInfoSaved] = useState(false);

  useEffect(() => {
    const userInfo = !!localStorage.getItem("userInfo");
    if (userInfo) {
      setUserInfoSaved(true);
    } else {
      setUserInfoSaved(false);
    }
  }, [userInfoSaved])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refererLang = urlParams.get('lang')?.split('-')[0];
    i18n.changeLanguage(refererLang ?? i18n.language.split('-')[0]);
  }, []);

  return (
    <div className="App" style={{backgroundColor: 'white'}}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Main userInfoSaved={userInfoSaved}
            setUserInfoSaved={setUserInfoSaved} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
