import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

import { outcome } from "./utils";

import './result.css';

export const Outcome = ({ type, restart }) =>  {
    const { t } = useTranslation('components/all');


    useEffect(() => {
        if (type === 'success') {
            setTimeout(() => window.close(), 2000)
        }
    }, [type])

    return(
        <>
            <Typography
                fontSize={30}
                fontWeight={"bold"}
                fontFamily={"Helvetica Neue"}
            >
                {t(outcome[type].title)}
            </Typography>
            <Typography
                fontSize={15}
                fontFamily={"Helvetica Neue"}
                fontWeight={"regular"}
                mx="30px"
                my="20px"
            >
                {t(outcome[type].message)}
            </Typography>
            {type !== 'success' && (
                <button 
                    onClick={restart}
                    className="retry"
                >
                    {t('camera.results.outcome.retry')}
                </button>
            )}

        </>
    )
}