import { useRef, useEffect,  useState } from "react"
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Webcam from 'react-webcam';
import { Box } from "@mui/material"

import { Inclination } from "./Inclination";
import { Tutorial } from "./Tutorial";
import { Countdown } from "./Countdown";
import { Results } from "./Results";

import { putS3, triggerMeasurement } from "../../../api/endpoints";
import { Pose } from "./Pose";

import { check } from "./Pose/utils/interpretation"

import * as poseDetection from '@tensorflow-models/pose-detection';
import * as tf from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-wasm';
//import '@mediapipe/pose';

const STEPS = {
    INCLINATION: "Inclination",
    FRONT: "Front",
    SIDE: "Side",
    POSE: "Pose",
    COUNTDOWN_FRONT: "Countdown Front",
    COUNTDOWN_SIDE: "Countdown Side",
    RESULTS: "Results"
}

export const Camera = ({
    restart, 
    previousScreen 
}) => {
    const { t } = useTranslation('components/all');

    const [searchParams, setSearchParams] = useSearchParams();
    const firstTutoSeen = useRef(false);
    const secondTutoSeen = useRef(false);

    const [step, setStep] = useState(STEPS.INCLINATION); // Change to STEPS.INCLINATION
    const stepNumber = useRef(0);

    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const mid = searchParams.get("mid") || ""
    const gender = searchParams.get("gender") || "male"

    const [result, setResult] = useState("loading");

    const camRef = useRef(null)

    const nextStep = (id) => {
        if (stepNumber.current === 0) {
            stepNumber.current = 1
            setStep(STEPS.FRONT)
        } else if (stepNumber.current === 1) {
            stepNumber.current = 2
            setStep(STEPS.POSE)
        } else if (stepNumber.current === 2) {
            stepNumber.current = 3
            setStep(STEPS.COUNTDOWN_FRONT)
        } else if (stepNumber.current === 3) {
            stepNumber.current = 4
            setStep(STEPS.SIDE)
        } else if (stepNumber.current === 4) {
            stepNumber.current = 5
            setStep(STEPS.POSE)
        } else if (stepNumber.current === 5) {
            stepNumber.current = 6
            setStep(STEPS.COUNTDOWN_SIDE)
        } else if (stepNumber.current === 6) {
            stepNumber.current = 7
            setStep(STEPS.RESULTS)
        }
    }

    const takePhoto = (type) => {
        if (!!camRef.current) {
            const imageSrc = camRef.current.getScreenshot();
            if (!!imageSrc) {
                const result = putS3(type, imageSrc).then((v) => {
                    if (type === 'side') {
                        if (v) {
                            triggerMeasurement(mid)
                            .then((res) => {
                                if (res) setResult("success")
                                else setResult("error")
                            })
                            .catch((e) => {
                                console.log('Error : triggerMeasurement -', e)
                                setResult("error")
                            })
                        } else {
                            setResult("error")
                        }
                    }
                })
            }
        }
        return false;
    }

    const [detector, setDetector] = useState(null)
    useEffect(() => {
        console.log('detector status', detector)
    }, [detector])

    const detectorCreated = useRef(false)
    const detectorCreating = useRef(false)
    const launchDetector = async () => {
        console.log('launchDetector started')
        try {
            detectorCreating.current = true
            await tf.ready();
            tf.engine().startScope()

            const model = poseDetection.SupportedModels.MoveNet
            const detectorConfig = {
                modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING
            }
            const detectorRes = await poseDetection.createDetector(model, detectorConfig);
            setDetector(detectorRes)
            detectorCreated.current = true
        } catch (e) {
            console.log("Error when creating the detector:", e)
            detectorCreated.current = false
        }
        console.log("detectorCreated after creation", detectorCreated)
        detectorCreating.current = false
    }

    const createDetector = (location) => {
        console.log('createDetector attempt from', location)
        if ((!detector || !detectorCreated.current) && (!detectorCreating.current)) {
            console.log('createDetector launched')
            setTimeout(() => {
                if (!detectorCreated.current && !detectorCreating.current) {
                    launchDetector()
                }
            }, 500)
        } else {
            console.log('detector already created')
        }
    }

    const removeDetector = () => {
        try {
            if (detector) {
                detector.dispose()
                detectorCreated.current = false
                setDetector(null);
            }

            setDetector(null)
            detectorCreated.current = false

            if (tf && tf.disposeVariables) {
                tf.disposeVariables();
            } 
            tf.engine().endScope();
            console.log('memory after end', tf.memory())
            
        } catch (error) {
            console.log('removeDetector error', error)
        }
    }

    useEffect(() => {
        const handleResize = () => {
          console.log('handleSize')
          setScreenWidth(window.innerWidth);
          setScreenHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log('camera step', step)
    }, [step])

    useEffect(() => {
        console.log('detector', detector)
    }, [detector])

    const [fullPose, setFullPose] = useState()
    const [imageHeight, setImageHeight] = useState();
    const [imageWidth, setImageWidth] = useState();

    const estimatePosesLaunched = useRef(false)
    const estimatePoses = async () => {
        if (detector) {
            if (camRef?.current && camRef.current.video && camRef.current.video.readyState === 4) {
                const videoRef = camRef.current.video
                try {
                    const poses = await detector.estimatePoses(videoRef)
                    if (poses[0]) {
                        estimatePosesLaunched.current = true
                        setFullPose(poses[0].keypoints)
                        setImageHeight(videoRef.videoHeight)
                        setImageWidth(videoRef.videoWidth)
                    }
                } catch (error) {
                    estimatePosesLaunched.current = false
                }
                
                requestAnimationFrame(estimatePoses);
            } else {
                setTimeout(estimatePoses, 200);
            }
        }
    }

    const launchEstimatePose = () => {
        if (!!(detector && camRef)) {
            console.log('launchEstimatePose passed')
            estimatePoses();
        }
    }

    const [comment, setComment] = useState(t('camera.index.loading'));
    const nextStepCalled = useRef({ "Front": false, "Side": false });

    const validateCoordinates = (data) => {
        const type = stepNumber.current === 2 ? STEPS.FRONT : stepNumber.current === 5 ? STEPS.SIDE : null
        const positionRes = check(data, type, imageHeight, imageWidth)
        if (positionRes !== null) {
            if (positionRes !== true) {
                setComment(positionRes)
            } else {
                setComment(null)
                if (!nextStepCalled.current[type]) {
                    nextStepCalled.current[type] = true;
                    nextStep('all good');
                }
            }
        } else {
            setComment(t('camera.index.poseComment'))
        }
    }

    useEffect(() => {
        if (fullPose) {
            validateCoordinates(fullPose)
        }
    }, [fullPose])

    useEffect(() => {
        if (!estimatePosesLaunched.current) {
            launchEstimatePose()
        }
    }, [detector])

    return (
        <Box sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
        }}>
            {step === STEPS.INCLINATION && (
                <Inclination
                    createDetector={() => { createDetector('Inclination') }}
                    previousScreen={previousScreen}
                    stepNumber={stepNumber.current}
                    startTutorial={() => { nextStep('inclinaison') }}
                />
            )}
            {step === STEPS.FRONT && (
                <Tutorial
                    tutoSeen={firstTutoSeen}
                    setComment={setComment}
                    launchEstimatePose={launchEstimatePose}
                    createDetector={() => { createDetector('Tuto 1') }}
                    onEnd={nextStep}
                    gender={gender}
                    index={1}
                />
            )}
            {step === STEPS.SIDE && (
                <Tutorial 
                    tutoSeen={secondTutoSeen}
                    setComment={setComment}
                    launchEstimatePose={launchEstimatePose}
                    createDetector={() => { createDetector('Tuto 2') }}
                    onEnd={nextStep}
                    gender={gender}
                    index={2}
                />
            )}
            {step === STEPS.POSE && (
                <Pose
                    comment={comment}
                    createDetector={() => { createDetector('Pose') }}
                    detector={detector}
                    detectorCreated={detectorCreated}

                />
            )}
            {(step === STEPS.COUNTDOWN_FRONT || step === STEPS.COUNTDOWN_SIDE) && (
                <Countdown
                    takePhoto={takePhoto}
                    removeDetector={removeDetector}
                    end={step === STEPS.COUNTDOWN_SIDE}
                    nextStep={() => { nextStep('coutdown') }}
                />
            )}
            {step === STEPS.RESULTS && (
                <Results type={result} restart={restart} />
            )}
            <Webcam
                ref={camRef}
                mirrored={true}
                height={screenHeight}
                wdith={screenWidth}
                screenshotFormat="image/png"
                style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}
                videoConstraints={{
                    facingMode: 'user',
                    aspectRatio: screenHeight/screenWidth
                }}
            />
        </Box>
    )
}
