import clothing from '../../../../assets/img/guidelines/clothing.svg';
import sun from '../../../../assets/img/guidelines/sun.svg';
import position from '../../../../assets/img/guidelines/position.svg';

export const GUIDELINES = {
    CLOTHING: {
        index: 0,
        body: 'guidelines.clothes',
        icon: clothing
    },
    SUN: {
        index: 1,
        body: 'guidelines.light',
        icon: sun
    },
    POSITION: {
        index: 2,
        body: 'guidelines.pose',
        icon: position
    }
}