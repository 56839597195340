import valid from "../../../../assets/img/valid.png"
import error from "../../../../assets/img/error.png"

export const outcome = {
    "success": {
        img: valid,
        title: 'camera.results.outcome.success.title',
        message: 'camera.results.outcome.success.message'
    },
    "error": {
        img: error,
        title: 'camera.results.outcome.error.title',
        message: 'camera.results.outcome.error.message'
    }
}