import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";


import front from "../../../../assets/img/tuto/front.png"
import side from "../../../../assets/img/tuto/side.png"

const IMG_SRC = {
    1: front,
    2: side
}

export const Tutorial = ({
    tutoSeen,
    createDetector,
    launchEstimatePose,
    onEnd,
    index
}) => {
    const { t } = useTranslation('components/all');

    const isSmallMobile = useMediaQuery({ maxWidth: 376 });
    const onEndCalled = useRef(false);
    const detectorCreated = useRef(false);
    const estimatePoseLaunched = useRef(false);

    useEffect(() => {
        if (!detectorCreated.current) {
            createDetector()
            detectorCreated.current = true
        }
    }, [])

    useEffect(() => {
        if (!estimatePoseLaunched.current) {
            setTimeout(() => {
                launchEstimatePose()
                estimatePoseLaunched.current = true
            }, 100)
        }
    }, [])

    useEffect(() => {
        if (tutoSeen.current && !onEndCalled.current) onEnd('ou là');
        else {
            if (!onEndCalled.current) {
                setTimeout(() => onEnd('ici'), 4000);
                onEndCalled.current = true;
            }
            tutoSeen.current = true;
        }
    }, [tutoSeen.current])
    return (
        <Box sx={{
            width: "100vw",
            height: "100vh",
            zIndex: 999999,
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "center",
                marginTop: '10%'
            }}>
                <Box sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Helvetica Neue',
                    fontSize: '15px'
                }}>
                    1
                </Box>
                <Box sx={{
                    width: '125px',
                    maxHeight: '1px',
                    borderColor: index === 2 ? 'white' : '#AEAAAA',
                    border: `1px ${index === 2 ? 'white' : '#AEAAAA'} solid`
                }}/>
                <Box sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: index === 2 ? 'white' : '#AEAAAA',
                    color: 'black',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Helvetica Neue',
                    fontSize: '15px'
                }}>
                    2
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "center",
                marginTop: '10px'
            }}>
                <Box sx={{
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Helvetica Neue',
                    fontSize: '15px'
                }}>
                    {t('camera.tutorial.front.title')}
                </Box>
                <Box sx={{ width: '125px' }}/>
                <Box sx={{
                    color: index === 2 ? 'white' : '#AEAAAA',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontFamily: 'Helvetica Neue',
                    fontSize: '15px'
                }}>
                    {t('camera.tutorial.side.title')}
                </Box>
            </Box>
            <img
                src={IMG_SRC[index]}
                style={{
                    marginTop: "5%",
                    height: isSmallMobile ? "55%" : "60%"
                }}  
            />
            <Typography
                fontFamily="Helvetica Neue"
                color="white"
                fontWeight={"bold"}
                fontSize={index === 2 ? "60px" : "30px"}
                sx={{
                    maxWidth: index === 1 ? "100%" : "60%",
                    margin: "10px",
                }}
            >
                {index === 1 ? t('camera.tutorial.front.guideline') : t('camera.tutorial.side.guideline')}
            </Typography>
        </Box>
    )
}
